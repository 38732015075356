<template>
  <div class="newsList">
    <!-- <div class="datanull" v-show="!listcontent||!listcontent.length">暂无数据！</div> -->
    <div
      v-for="(item,index) in listcontent"
      :key="index"
      class="boxContent"
      @click="enterDetails(item)"
    >
      <div
        class="nophoto"
        v-if="!item.contributionCover||!JSON.parse(item.contributionCover).length"
      >
        <div class="newsTitle nophotoTitle">
          <p class="titleName">{{item.mainContributionTitle}}</p>
          <div class="sorceTime">
            <span v-if="item.principal">{{item.contributionSource}}</span>
            <span v-if="dateBlock">{{item.releaseTime.slice(0, 11)}}</span>
          </div>
        </div>
      </div>
      <div
        class="oneImg"
        v-else-if="JSON.parse(item.contributionCover).length<3&&index%4!=0&&!item.contributionVideo"
      >
        <div class="newsTitle">
          <p class="titleName">{{item.mainContributionTitle}}</p>
          <div class="sorceTime">
            <span v-if="item.principal">{{item.contributionSource}}</span>
            <span v-if="dateBlock">{{item.releaseTime.slice(0, 11)}}</span>
          </div>
        </div>
        <div class="newsThumbOne">
          <img
            class="newsThumb"
            v-if="JSON.parse(item.contributionCover)[0]"
            :src="JSON.parse(item.contributionCover)[0]"
            alt
          />
          <img class="newsThumb" v-else src="../../assets/images/default.png" alt />
          <img
            src="../../assets/vedioSing.png"
            alt
            class="vedioPlaySign"
            v-if="item.contributionVideo"
          />
        </div>
      </div>
      <div
        class="moreImg moreAndOne"
        v-else-if="(JSON.parse(item.contributionCover).length<3&&index%4==0)||item.contributionVideo"
      >
        <p class="moreAndOneTitle">{{item.mainContributionTitle}}</p>
        <div class="newsThumbBox newsThumbBoxOne">
          <img
            class="newsThumb"
            v-if="JSON.parse(item.contributionCover)[0]"
            :src="JSON.parse(item.contributionCover)[0]"
            alt
          />
          <img class="newsThumb" v-else src="../../assets/images/default.png" alt />
          <img
            src="../../assets/vedioSing.png"
            alt
            class="vedioPlaySign morevedioPlaySign"
            v-if="item.contributionVideo"
          />
        </div>
        <div class="sorceTime">
          <span v-if="item.principal">{{item.contributionSource}}</span>
          <span v-if="dateBlock">{{item.releaseTime.slice(0, 11)}}</span>
        </div>
      </div>
      <div v-else class="moreImg">
        <p class="titleName">{{item.mainContributionTitle}}</p>
        <div class="newsThumbBox">
          <div v-for="(imgper,index) in JSON.parse(item.contributionCover)" :key="index">
            <img class="newsThumb" v-if="index<3 && imgper" :src="imgper" alt />
            <img class="newsThumb" v-else src="../../assets/images/default.png" alt />
          </div>
        </div>
        <div class="sorceTime">
          <span v-if="item.principal">{{item.contributionSource}}</span>
          <span v-if="dateBlock">{{item.releaseTime.slice(0, 11)}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props:["listcontent"],
  props: {
    listcontent: {
      //是否显示
      type: Array,
      default: () => [], //必须是一个函数
      required: false,
    },
    dateBlock: {
      type: Boolean,
      default: () => true, //必须是一个函数
      required: false, //不是必填项
    },
  },
  data() {
    return {
    };
  },
  methods: {
    enterDetails(item) {
      console.log(item);
      var link;
      if (item.contributionOtherUrl) {
        link = item.contributionOtherUrl; //链接
      } else if (item.contributionType == 'IMAGE_TYPE') {
        link = window.location.href.split("#/")[0] + "#/tabloid?id=" + item.id; //链接
      } else {
        link = window.location.href.split("#/")[0] + "#/detailsTwo?id=" + item.id; //链接
      }
      window.location.href = link;
    },
  },
};
</script>

<style  scoped>
.datanull {
  width: 100%;
  height: 100%;
  color: #999999;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.boxContent {
  width: calc(100% - 24px);
  padding: 0.24rem 0px;
  margin: 0px auto;
  box-sizing: border-box;
  border-bottom: 1px solid #eeeeee;
}
.oneImg {
  width: 100%;
  /* height: 1.6rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nophoto {
  width: 100%;
  /* height: 60px; */
}
.newsThumbBox {
  width: 100%;
  height: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newsThumbBoxOne {
  height: 3.7rem;
  position: relative;
}
.newsThumbBox > div {
  width: 32%;
  height: 100%;
}
.newsThumbOne {
  width: 32%;
  height: 100%;
  position: relative;
}
.newsThumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.vedioPlaySign {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  object-fit: contain;
  z-index: 999;
}
.morevedioPlaySign {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}
.newsTitle {
  width: 64%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.nophotoTitle {
  width: 100%;
}
.titleName {
  width: 100%;
  /* height:37px; */
  color: #333;
  font-size: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.moreAndOneTitle {
  width: 100%;
  /* height:37px; */
  color: #333;
  font-size: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sorceTime {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: 0.24rem;
  padding-top: 5px;
}

/* 后期多图 */
.moreImg {
  width: 100%;
  /* height: 2.5rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
}
.moreAndOne {
  height: 4.65rem;
}
</style>