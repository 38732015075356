<template>
  <div class="searchPage">
    <div class="searchPageHead">
      <div class="searchContent">
        <i class="iconfont icon-fanhui" @click="goBack"></i>
        <div class="inputBox">
          <input
            type="text"
            placeholder="请输入内容"
            autocomplete
            class="searchLink"
            v-model="searchValue"
          />
          <i class="iconfont icon-close-b" v-show="searchValue" @click.stop="clearData"></i>
        </div>
        <button class="sousuoCon" @click="onSearch">搜索</button>
      </div>
    </div>
    <mescroll-vue
      class="voluntaryRecruitment gundong"
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
    >
      <nesList :listcontent="dataList"></nesList>
      <div id="empty" style="margin-top:25vh"></div>
    </mescroll-vue>
  </div>
</template>

<script>
// 引入mescroll的vue组件
import MescrollVue from 'mescroll.js/mescroll.vue'
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
import nesList from "./czComponents/NewsList"
import Homeapi from "@/api/home";

export default {
  components: {
    nesList,
    MescrollVue
  },
  data() {
    return {
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, //下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        //以下是一些常用的配置,当然不写也可以的.
        page: {
          num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 10 //每页数据条数,默认10
        },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "empty", //父布局的id (1.3.5版本支持传入dom元素)
          icon: require('../../src/assets/mescroll/mescroll-empty.png'), // 图标,支持网络图
          tip: "暂无数据" //提示
        }
      },
      params: {
        page: 1,
        sise: 10
      },
      searchValue: '',  // 搜索框输入的活动名
      dataList: [],
      emptyData: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == '/home') {
      this.emptyData = false;
      this.clearData(); // 清除数据
      var set = setInterval(() => {
        if (this.emptyData) {
          this.$nextTick(() => {
            clearInterval(set);
            next();
          })
        }
      }, 50);
    } else {
      next();
    }
  },
  methods: {
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
    // 清除
    clearData() {
      this.searchValue = ""; //清空数据，调接口
      this.dataList = [];
      this.onSearch();
    },
    mescrollInit(mescroll) { //滚动对象
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      let data = {
        title: this.searchValue,
        page: page.num,
        rows: page.size
      }
      let params = this.appName

      Homeapi.searchNew(params, data)
        .then(res => {
          let arr = res.data.items;
          if (arr) {
            if (!arr.length && this.searchValue) {
              Toast('暂无数据！');
            }
            if (page.num === 1) {
              this.dataList = [];
            }
            this.dataList = this.dataList.concat(arr);
            // console.log(this.dataList);
            this.$nextTick(() => {
              mescroll.endSuccess(arr.length);
            })
          } else {
            if (page.num === 1) {
              this.dataList = [];
            }
            this.$nextTick(() => {
              mescroll.endSuccess(0);
            })
          }
          this.emptyData = true;
        }).catch((err) => {
          this.emptyData = true;
          mescroll.endErr();
        })
    },
    onSearch() {
      var page = {
        num: 1,
        size: 10,
      }
      this.upCallback(page, this.mescroll);
    },
    // 点击清除按钮
    onClear() {
      this.onSearch();
    }
  }
}
</script>
<style>
/* .van-search {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0;
    background-color: #fff;
} */
input::-webkit-input-placeholder {
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.moreNavTwo {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
}
.searchPage {
  width: 100%;
  height: 100%;
}
.searchPageHead {
  width: 100%;
  /* height: 74px; */
  box-sizing: border-box;
  background: #efefef;
  /* background:rgba(239.7, 239.7, 239.7, 1); */
  /* padding-top: 24px; */
  /* background: #186AB5; */
}
.searchContent {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 10px;
}
.icon-fanhui {
  color: #676767;
  font-size: 18px;
}
.inputBox {
  width: calc(100% - 80px);
  height: 34px;
  position: relative;
}

.icon-close-b {
  position: absolute;
  right: 13px;
  top: 8.5px;
  color: #ccc;
}
.searchLink {
  width: 100%;
  height: 34px;
  font-size: 16px;
  /* border: 1px solid #186AB5; */
  border-radius: 100px;
  padding: 0px 20px;
  // padding-left: 30px;
  box-sizing: border-box;
  background: #f7f8fa;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 34px;
}
.searchLink::placeholder {
  color: #adadad;
}
.sousuoCon {
  /* background-image: linear-gradient(#54a0e7, #186AB5); */
  border: none;
  outline: none;
  height: 30px;
  padding: 0px 10px;
  color: #676767;
  background: transparent;
  font-size: 14px;
}
.searchDataContent {
  width: 100%;
  height: calc(100% - 84px);
  overflow-y: auto;
}
.contentNeirong {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.voluntaryRecruitment {
  width: 100%;
  height: calc(100% - 84px);
  overflow-y: auto;
  padding: 0 0;
  background-color: #fff;
}
</style>
